import { flow, Instance, SnapshotIn, types } from 'mobx-state-tree';
import { withRootStore } from '../helpers/with-root-store';
import { Gender, Goal } from '@/models/api';
import _ from 'lodash';
import { API_GOAL_MAP, BUCKET_CONFIG } from '@/utils/api-constants';
import { parseToNumber, randomId, roundTotal } from '@/utils/helpers';
import { OrderPlan, OrderPlanModel } from '../cart/order-plan';
import { Cart } from '../cart/cart';
import { formatCartOrderPlan } from '../helpers/cart';
import { GENERATE_CUSTOM_PLAN_SELECTOR } from '@/graphql/selectors';
import { SWAP_CONFIG_SELECTOR } from '@/graphql/selectors/goalBased';
import * as Sentry from '@sentry/react';
import DefaultSwapConfig from '@/mock-data/swap-config.json';
import {
  trackMealPlanFlowStepCompleted,
  trackMealPlanFlowStepViewed,
} from '@/utils/track/tracker.helper';

export const MealPlanModel = types
  .model('MealPlanStore')
  .props({
    planId: types.optional(types.string, randomId(48)),
    goal: types.maybe(types.enumeration(Object.values(Goal))),
    gender: types.maybe(types.enumeration(Object.values(Gender))),
    exercise: types.maybe(types.string),
    bucket: types.maybe(types.number),
    numberOfDays: types.maybe(types.string),
    numberOfMeals: types.maybe(types.string),
    weight: types.maybe(types.number),
    ranges: types.optional(types.array(types.string), []),
    proteinTypes: types.optional(types.array(types.string), []),
    steps: types.optional(types.array(types.string), []),
    nextStep: types.maybe(types.string),
    swapConfig: types.maybe(types.frozen()),
    swapCategory: types.maybe(types.string),
    plan: types.maybe(OrderPlanModel),
  })
  .extend(withRootStore)
  .views((self) => ({
    get currentStep(): string | undefined {
      return _.last(self.steps);
    },
    get numberOfSteps(): number {
      return self.goal === Goal.SAVETIME ? 3 : 4;
    },
    get selectedSwapConfig(): any {
      const defaultConfig = self?.swapConfig?.find(
        (config: any) => config.goal === 'default',
      );
      if (!self.goal) return defaultConfig;
      const found = self?.swapConfig?.find(
        (config: any) => config.goal === _.get(API_GOAL_MAP, self.goal!),
      );
      if (!found) {
        return defaultConfig;
      }
      return found;
    },
    get currentPlanId(): string {
      return self.plan?.planId || self.planId;
    },
    get allowedHierarchies(): string[] {
      if (!this.selectedSwapConfig) return [];
      const menuHierarchyList = this.selectedSwapConfig?.swapCategory?.find(
        (config: any) => config.key === self.swapCategory,
      );
      const menuHierarchyKeys = menuHierarchyList?.menuHierarchy?.map(
        (menuHierarchy: any) => menuHierarchy.key,
      );
      return menuHierarchyKeys;
    },
    get flattenProteinTypes(): string[] {
      return _.flatMap(self.proteinTypes, (p) => _.split(p, ','));
    },
    get preset(): object {
      const allPreset = {
        key: self.planId,
        goal: self.goal,
        gender: self.gender,
        weight: self.weight,
        exerciseKey: self.exercise,
        bucket: parseToNumber(self.exercise) || 4,
        numberOfDays: parseToNumber(self.numberOfDays),
        numberOfMeals: parseToNumber(self.numberOfMeals),
        ranges: _.concat('MEALS', self.ranges), // hardcoded the MEALS in the ranges
        proteinTypes: self.proteinTypes.includes('All')
          ? []
          : this.flattenProteinTypes,
      };
      // filter the preset based on the goal
      const commPickList = ['key', 'goal'];
      const pickList =
        self.goal === Goal.SAVETIME
          ? ['numberOfMeals', 'proteinTypes', 'ranges']
          : ['gender', 'weight', 'exerciseKey', 'bucket', 'numberOfDays'];
      return _.pick(allPreset, [...commPickList, ...pickList]);
    },
    calculateCalories(
      goal: Goal,
      gender: Gender,
      weight: number = 0,
      bucket: number = 0,
    ): number {
      const {
        calConfig: { base, coe },
      } = BUCKET_CONFIG[goal];
      const offset = gender === Gender.MALE ? 0 : 7.5;
      const calKg = ((base + bucket * coe) * (100 - offset)) / 100;
      const cal = roundTotal(calKg * weight, 0);
      return cal;
    },
  }))
  .actions((self) => ({
    setValue<K extends keyof SnapshotIn<typeof self>>(
      key: K,
      value: SnapshotIn<typeof self>[K],
    ) {
      self[key] = value;
    },
    setNextStep(step: string) {
      self.nextStep = step;
    },
    pushNextStep(step: string) {
      try {
        trackMealPlanFlowStepCompleted({
          meal_plan_id: self.currentPlanId,
          flow_goal: self.goal,
          step: self.steps.length + 1,
          step_name: self.currentStep || 'goal',
        });
      } catch (error) {
        // ignore error
      }
      self.steps.push(step);
      try {
        trackMealPlanFlowStepViewed({
          meal_plan_id: self.currentPlanId,
          flow_goal: self.goal,
          step: self.steps.length + 1,
          step_name: self.currentStep || 'goal',
        });
      } catch (error) {
        // ignore error
      }
    },
    revertPrevStep() {
      self.steps.pop();
      try {
        trackMealPlanFlowStepViewed({
          meal_plan_id: self.currentPlanId,
          flow_goal: self.goal,
          step: self.steps.length + 1,
          step_name: self.currentStep || 'goal',
        });
      } catch (error) {
        // ignore error
      }
    },
    resetSteps() {
      self.steps.clear();
    },
    setSwapCategory(category: string): void {
      self.swapCategory = category;
    },
    reset() {
      self.planId = randomId(48);
      self.goal = undefined;
      self.gender = undefined;
      self.exercise = undefined;
      self.numberOfDays = undefined;
      self.numberOfMeals = undefined;
      self.weight = undefined;
      self.bucket = undefined;
      self.ranges.clear();
      self.proteinTypes.clear();
      self.steps.clear();
    },
    setPlan(plan: Instance<typeof OrderPlanModel>) {
      self.plan = plan;
    },
    resetPlan() {
      self.planId = randomId(48);
      self.plan = undefined;
    },
  }))
  .actions((self) => ({
    generatePlan: flow(function* (
      cart: Cart,
      preset: any,
      email?: string,
      unsubscribeEmail?: boolean,
    ) {
      const plans = _.map(cart.plans, (plan: OrderPlan) =>
        formatCartOrderPlan(plan),
      );
      const plansObj = { plans };
      const payload = {
        cart: plansObj,
        email,
        unsubscribeEmail,
        ...preset,
      };
      try {
        const { generatePlan: plan } =
          yield self.rootStore.api.mutateGeneratePlan(
            payload,
            GENERATE_CUSTOM_PLAN_SELECTOR,
          );
        if (plan.plan) {
          plan.plan.image = plan.cms?.squareImage?.url;
        }
        self.setPlan(plan.plan);
        if (plan.message) {
          self.rootStore.generalStore.createNotification(plan.message);
        }
        return self.plan as OrderPlan;
      } catch (error: any) {
        Sentry.captureException(error);
        throw error;
      }
    }),
    getGeneratedPlan: flow(function* (planId: string) {
      try {
        const variables = { key: planId };
        const { generatedPlan: plan } =
          yield self.rootStore.api.queryGeneratedPlan(
            variables,
            GENERATE_CUSTOM_PLAN_SELECTOR,
          ).promise;
        if (plan.plan && !plan.plan.image) {
          plan.plan.image = plan.cms?.squareImage?.url;
        }
        self.setPlan(plan.plan);
        if (plan.message) {
          self.rootStore.generalStore.createNotification(plan.message);
        }
        return self.plan as OrderPlan;
      } catch (error: any) {
        Sentry.captureException(error);
        throw error;
      }
    }),
    updateGeneratedPlan: flow(function* (plan: OrderPlan) {
      const variables = { key: plan.planId, plan: formatCartOrderPlan(plan) };
      try {
        yield self.rootStore.api.mutateUpdateGeneratedPlan(
          variables,
          GENERATE_CUSTOM_PLAN_SELECTOR,
        );
      } catch (error: any) {
        Sentry.captureException(error);
        throw error;
      }
    }),

    getSwapConfig: flow(function* () {
      try {
        const response = yield self.rootStore.api.queryAllSwapConfigsCms(
          { filter: { active: { eq: true } } },
          SWAP_CONFIG_SELECTOR,
        ).promise;
        const { allSwapConfigsCms } = response;
        self.swapConfig = allSwapConfigsCms;
      } catch (error: any) {
        self.swapConfig = DefaultSwapConfig;
        Sentry.captureException(error);
      }
    }),
  }));

export type MealPlanType = Instance<typeof MealPlanModel>;
export interface MealPlanStore extends MealPlanType {}
